#root {
  background: linear-gradient(180deg, rgba(10,29,43,.75) 0%, rgba(18,18,18,1) 8%, rgba(18,18,18,1) 92%, rgba(10,29,43,.75) 100%);
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: black;
}

.MuiInputLabel-asterisk, .MuiFormControlLabel-asterisk {
  display: none;
}

/* TOP NAVIGATION */

.nav-item-products .MuiMenu-paper {
  width: 10%;
}

/* SERVER PLANS PAGE */

.MuiStepIcon-text {
  font-weight: 500
}

/* PLAN CARD STYLES */

.plan-card {
  border: 1px solid rgba(250, 230, 150, 0); 
}

.plan-card:hover {
  animation: pulse 1.5s infinite;
  transform: scale(1.02);
  cursor: pointer;
}

.plan-card.active {
  border: 1px solid #fae696; 
  box-shadow: 0 0 12px rgba(250, 230, 150, .2);
  transition: all .3s ease-in-out;
}

.plan-card .plan-card-cta::before {
  content: 'Select\00a0';
}

.plan-card.active .plan-card-cta::before {
  content: '';
}

.plan-card.active .plan-card-cta::after {
  content: '\00a0 selected';
}

/* POWERUP BLOCK STYLES */

.power-ups-wm {
  width: 100%;
  display: block;
}

.power-up-block {
  padding: 16px;
  background: linear-gradient(30deg, rgba(255, 255, 255, .05) 0%, rgba(255, 255, 255, .01) 30%, rgba(255, 255, 255, .01) 70%, rgba(255, 255, 255, .05) 100%);
  border-radius: 4px;
  text-align: left;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, .2);
  animation: none;
}

.power-up-block.disabled {
  cursor: not-allowed;
}

.power-up-block.disabled:hover {
  animation: none;
}

.power-up-block.active {
  border: 1px solid #fae696; 
  box-shadow: 0 0 12px rgba(250, 230, 150, .2);
  transition: all .3s ease-in-out;
}

.power-up-block .power-up-cta-text {
  font-weight: 500;
  color: #a2d4fa;
  transition: color .3s ease-in-out;
}

.power-up-block.active .power-up-cta-text {
  color: #fae696;
  transition: color .3s ease-in-out;
}

.power-up-block .power-up-cta-text::after {
  content: 'Activate';
  animation-fill-mode: reverse;
  animation: power-up-cta-text-change-active .3s ease-in-out reverse;
}

.power-up-block.active .power-up-cta-text::after {
  animation-fill-mode: forwards;
  animation: power-up-cta-text-change-active .3s ease-in-out forwards;
}

@keyframes power-up-cta-text-change-active {
  0% {
    opacity: 1;
    content: 'Activate';
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    content: 'Activated';
  }
}

@-webkit-keyframes power-up-cta-text-change-active {
  0% {
    opacity: 1;
    content: 'Activate';
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    content: 'Activated';
  }
}

.power-up-block:hover {
  animation: pulse 1.5s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 rgba(250, 230, 150, 1);
  }
  70% {
      -webkit-box-shadow: 0 0 24px rgba(250, 230, 150, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 rgba(250, 230, 150, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 rgba(250, 230, 150, 1);
    box-shadow: 0 0 0 rgba(250, 230, 150, 1);
  }
  70% {
      -moz-box-shadow: 0 0 24px rgba(250, 230, 150, 0);
      box-shadow: 0 0 24px rgba(250, 230, 150, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 rgba(250, 230, 150, 0);
      box-shadow: 0 0 0 rgba(250, 230, 150, 0);
  }
}

.monero-qr-code svg {
  width: 100%;
}

/* PURCHASE COMPLETED DIALOG */

.purchase-completed-dialog .MuiPaper-root {
  animation: pulse 1.5s infinite;
}

@media screen and (min-width: 1200px) {
  
  #root {
    background: linear-gradient(119deg, rgba(10,29,43,1) 0%, rgba(18,18,18,1) 15%, rgba(18,18,18,1) 85%, rgba(10,29,43,1) 100%);
  }
    
}