/* Main Heading Font Sweet Square Pro Rulesets */

@font-face {
  font-family: 'sweet-square-pro';
  src:  local('sweet-square-pro'),
        url('./sweet-square-pro.otf') format('opentype');
}

/* Backup Font Chakra Petch Rulesets */

@font-face {
  font-family: 'Chakra Petch';
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src:  local('ChakraPetch-Bold'),
        url('ChakraPetch-Bold.ttf') format('truetype');
}

/* Main Body Font Roboto Rulesets */

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-display: auto;
  src: local('Roboto-Light'),
       url('Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-display: auto;
  src: local('Roboto-Regular'),
       url('Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-display: auto;
  src: local('Roboto-Medium'),
       url('Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-display: auto;
  src: local('Roboto-Bold'),
       url('Roboto-Bold.ttf') format('truetype');
}